<template>
    <div>
        <div class="d-flex flex-wrap my-3">
            <div class="col-12 col-lg-10 text-center">
                <h1 class="h2">
                    Servicios para
                    {{ selectedMerchant.merchantType | merchantInfo("type") }}
                    <strong class="text-primary">
                        {{ selectedMerchant.name }}
                    </strong>
                </h1>
            </div>
            <div
                class="col-12 col-lg-2 d-flex justify-content-center align-items-center"
            >
                <vs-button
                    circle
                    icon
                    floating
                    @click="listMerchantsServiceComponent"
                >
                    <reload-icon />
                </vs-button>
            </div>
        </div>

        <div>
            <span>
                En esta sección, puedes registrar los servicios adicionales que
                ofreces en tu tienda, como el laminado de carnets o la
                realización de fotocopias, podrás usar los servicios registrados
                en tus ventas. Es importante tener en cuenta que las ventas
                generadas a través de estos servicios no impactan el inventario
                de tu comercio.
            </span>
        </div>

        <div v-if="isLoading" class="d-flex justify-content-center">
            <span class="my-3"> Cargando... </span>
        </div>

        <div v-else>
            <services-in-merchant-manage
                v-model="services"
                :enabled="$ability.hasScope('merchant:UpdateServices')"
                class="mt-4"
            />
            <div class="d-flex flex-wrap mt-4">
                <div class="col-12 d-flex justify-content-center">
                    <vs-button @click="updateServicesMerchantComponent">
                        Actualizar servicios
                    </vs-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import ReloadIcon from "@/components/icons/ReloadIcon.vue";
import ServicesInMerchantManage from "@/components/utils/ServicesInMerchantManage.vue";

export default {
    name: "ServicesDetails",
    components: {
        ServicesInMerchantManage,
        ReloadIcon
    },
    data: () => ({
        services: [],
        isLoading: false,
        loaderInstance: null
    }),
    computed: {
        ...mapState("commons", ["selectedMerchant"]),
        ...mapGetters("control", ["backgroundColor"])
    },
    watch: {},
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (!(await verifyPageScope("merchant:ListServices", "/app", true)))
            return;
        await this.listMerchantsServiceComponent();
    },
    methods: {
        ...mapActions("merchants", [
            "listServicesMerchant",
            "updateServicesMerchant"
        ]),
        async listMerchantsServiceComponent() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "corners" });
            try {
                const services = await this.listServicesMerchant({
                    merchantId: this.selectedMerchant.merchantId,
                    merchantType: this.selectedMerchant.merchantType
                });
                this.services = services.map((item) => ({
                    name: item.product.name,
                    priceOffer: item.priceOffer
                }));
            } catch (error) {
                this.isLoading = false;
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async updateServicesMerchantComponent() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "corners" });
            try {
                await this.updateServicesMerchant({
                    merchantId: this.selectedMerchant.merchantId,
                    merchantType: this.selectedMerchant.merchantType,
                    services: this.services.map((item) => ({
                        appliedDiscountPerReference: 0,
                        priceOffer: item.priceOffer,
                        product: {
                            name: item.name
                        },
                        quantity: 1,
                        id: 0
                    }))
                });
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        }
    }
};
</script>
